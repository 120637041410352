import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import NotFoundScreen from '../../screens/NotFoundScreen'
import HomeScreen from '../../screens/HomeScreen'
import SideBar from '../../components/layout/SideBar'
import SubmissionNavigator from '../../screens/Submission/SubmissionNavigator'
import CallForSubmissionNavigator from '../../screens/CallForSubmission/CallForSubmissionNavigator'
import useIsInBreakpoint from '../../hooks/useIsInBreakpoint'
import { Breakpoint } from '../../types/breakpoint'
import theme from '../../constants/Theme'
import AppHeaderContainer from '../../components/layout/AppHeaderContainer'
import CurationNavigator from '../../screens/Curation/CurationNavigator'
import AdviseNavigator from '../../screens/Advise/AdviseNavigator'
import InnovatorDirectoryNavigator from '../../screens/InnovatorDirectory/InnovatorDirectoryNavigator'
import DownloadStatus from '../../screens/common/DownloadStatus'
import PlatformManagementTabs from '../../screens/PlatformManagement/PlatformManagementTabs'
import FormManagementTabs from '../../screens/FormManagement/FormManagementTabs'
import { AppDrawerParamList } from '../../types/rootStackParamsList'

const AppViewWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`
const AppViewScrollArea = styled(View)`
  flex: 1;
`

const Drawer = createDrawerNavigator<AppDrawerParamList>()

const AppDrawerNavigator = () => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsReady(true)
    })
  }, [])

  const isPermanentSidebar = useIsInBreakpoint(
    Breakpoint.Tablet,
    Breakpoint.Desktop,
    Breakpoint.Phone
  )

  return (
    <AppViewWrapper>
      <AppHeaderContainer />
      {isReady && (
        <AppViewScrollArea>
          <Drawer.Navigator
            backBehavior="history"
            screenOptions={{
              headerShown: false,
              drawerType: isPermanentSidebar ? 'front' : 'permanent',
              drawerStyle: {
                width: theme.sideBar,
                marginRight: 0
              }
            }}
            drawerContent={() => <SideBar />}
            initialRouteName="Home"
          >
            <Drawer.Screen name="Submission" component={SubmissionNavigator} />
            <Drawer.Screen
              name="CallForSubmission"
              component={CallForSubmissionNavigator}
            />
            <Drawer.Screen
              name="InnovatorDirectory"
              component={InnovatorDirectoryNavigator}
            />
            <Drawer.Screen name="Curate" component={CurationNavigator} />
            <Drawer.Screen name="Advise" component={AdviseNavigator} />
            <Drawer.Screen
              name="PlatformManagement"
              component={PlatformManagementTabs}
            />
            <Drawer.Screen
              name="FormManagement"
              component={FormManagementTabs}
            />
            <Drawer.Screen name="Home" component={HomeScreen} />
            <Drawer.Screen name="NotFound" component={NotFoundScreen} />
          </Drawer.Navigator>
          <DownloadStatus />
        </AppViewScrollArea>
      )}
    </AppViewWrapper>
  )
}

export default AppDrawerNavigator
